import React, { createContext, useReducer, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const StateContext = createContext()
export const DispatchContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LANGUAGE": {
      return {
        ...state,
        language: action.value,
      }
    }
    // case "TOGGLE_ACTIVITY": {
    //   return {
    //     ...state,
    //     label: action.value,
    //   }
    // }
    default:
      throw new Error("Bad Action Type")
  }
}

export const useStateValue = () => useContext(StateContext)

const StateContextProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query Storage {
      markdown: allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          frontmatter {
            date(formatString: "DD. MM. YYYY.")
            image
            label
            title
            lang
          }
          html
        }
      }

      images: allCloudinaryMedia(
        filter: { secure_url: { regex: "^/activities/" } }
      ) {
        edges {
          node {
            secure_url
            public_id
          }
        }
      }
    }
  `)

  const initialState = {
    language: "rs",
    data,
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export default StateContextProvider
